<template>
    <div class=""> 
        <compone-header :process="process"></compone-header>
        <div class="wrap">
            <p class="backlogin" v-if="$t('lang') == 'cn'">已有账号？<a :href="`${$store.state.domain}/login`" class="a">返回登录</a></p>
            <p class="backlogin" v-else><a :href="`${$store.state.domain_en}/login`" class="a"><el-button type="primary" icon="RefreshLeft">Return to login</el-button> </a></p>
        </div>

        <div class="form-content wrap" v-if="process == 1">
            <from-content @regSuccess="regSuccess"></from-content>
        </div>

        <div class="content wrap" v-if="process == 2">
            <finish :data = "userData"></finish>
        </div>

        <regFooter v-if="$t('lang') == 'cn'"></regFooter>
        <regFooterEn v-else></regFooterEn>
    </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import ComponeHeader from './components/header.vue'
import FromContent from './components/FromContent.vue'
import regFooter from './components/regFooter.vue'
import regFooterEn from './components/regFooterEn.vue'
import finish from './components/finish.vue'

defineEmits("regSuccess")
const process = ref(1);
const userData = ref(null)

function regSuccess(data) {
    userData.value = data;
    process.value = 2;
}
</script>

<style lang="less" scoped>
.form-content{
    margin-bottom: 40px;
}

.backlogin {
    text-align: right;
    color: #999;
    margin: 20px 0;
    .a {
      color: @color1;
    }
  }
</style>