<template>
  <footer class="reg-footer">
	<div class="wrap">
		<div class="foot-con">
			<div class="left">
				<div class="foot-logo">
					<img src="@/assets/images/logo2.png" alt="">
					<img src="@/assets/images/foot-logo3.png" alt="" class="mb-show">
				</div>
				<p class="tit">Comexposium-SIAL Exhibition Co., Ltd</p>
				<p class="p" style="align-items: flex-start;"><img src="@/assets/images/ico-address.png" alt=""> Suite 1401, Prime Tower, No.22 Chaoyangmenwai Dajie, Beijing, China</p>
				<p class="p"><img src="@/assets/images/ico-phone.png" alt=""> +86 (10) 6588 6794</p>
				<p class="p"><img src="@/assets/images/ico-email2.png" alt=""> <a href="https://visitor.sialchina.com/info_subscription" target="_blank">Subscribe to Email</a></p>
			</div>
			<div class="right">
				<ul class="foot-links">
					<li>
						<p class="tit">About Us</p>
						<a href="https://www.sialchina.com/sialchina/aboutus.html" target="_blank">SIAL China</a>
						<a href="https://www.sialchina.com/sialchina/global.html" target="_blank" style="white-space: nowrap;">SIAL Network</a>
						<a href="https://www.sialchina.com/sialchina/partner.html" target="_blank">Partners</a>
						<a href="https://www.sialchina.com/download.html" target="_blank">Whitepaper</a>
					</li>
					<li>
						<p class="tit">Exhibitor</p>
						<a href="https://www.sialchina.com/exhibitor/registration.html" target="_blank">Booth Booking</a>
						<a href="https://www.sialchina.com/exhibitor/login.html" target="_blank">Exhibitor Center</a>
						<a href="https://www.sialchina.com/exhibitor/themehall.html" target="_blank" style="white-space: nowrap;">Highlight Sectors</a>
					</li>
					<li>
						<p class="tit">Visitor</p>
						<a href="https://www.sialchina.com/visitor/visitorreg.html" target="_blank" style="display: inline-block;">Apply to Visit <span class="hot">Hot</span></a><br>
						<a href="https://www.sialchina.com/visitor/matchme.html" target="_blank">Match Me Buyers</a>
						<a href="https://www.sialchina.com/visitor/delegation.html" target="_blank" style="white-space: nowrap;">Delegation Buyers</a>
					</li>
					<li>
						<p class="tit">Online Marketplace</p>
						<a href="https://www.sialchina.com/exhibitorlistsz?year=2024" target="_blank">Shenzhen <br> Exhibitor List 2024</a>
						<a href="https://www.sialchina.com/productlistsz.html" target="_blank">Shenzhen<br> Product List 2024</a>
						<a href="https://www.sialchina.com/exhibitorlist.html" target="_blank">Shanghai<br> Exhibitor List 2024</a>
						<a href="https://www.sialchina.com/productlist.html" target="_blank">Shanghai<br> Product List 2024</a>
					</li>
					<li>
						<p class="tit">Industry Summit</p>
						<a href="https://www.sialchina.com/events_about.html" target="_blank" style="white-space: nowrap;">Summit Basic Info</a>
						<a href="https://www.sialchina.com/events_review.html" target="_blank">Summit Review</a>
						<a href="https://www.sialchina.com/perspective.html" target="_blank">Highlight Speech</a>
					</li>
					<li>
						<p class="tit">Events&amp;Activities</p>
						<a href="https://www.sialchina.com/events/sialinnovation.html" target="_blank">SlAL Innovation</a>
						<a href="https://www.sialchina.com/activities.html" target="_blank">Events & Competitions</a>
					</li>
					<li>
						<p class="tit">Contact Us</p>
						<a href="https://www.sialchina.com/contact/visitor-media.html" target="_blank">Visitor &amp; Media</a>
						<a href="https://www.sialchina.com/contact/exhibitor.html" target="_blank">Booth Booking</a>
						<a href="https://www.sialchina.com/contact/live.html" target="_blank">Forums &amp; Events Sponsor</a>
						<a href="https://www.sialchina.com/contact/arrived.html" target="_blank">Travel &amp; Accomodation</a>
					</li>
				</ul>
				<div class="foot-unit">
					
					<div class="foot-contact">
						<p class="p">
							Booth Enquiry: <br><br>
							<a href="mailto:exhibit@sialchina.cn">exhibit@sialchina.cn</a> <span class="txt">(Domestic Contact)</span><br><br>
							<a href="mailto:exhibit.sialchina@sial-network.com">exhibit.sialchina@sial-network.com</a> <br> <span class="txt">(International Contact)</span>
						</p>
						<p class="p">Badge Enquiry: <a href="mailto:visit@sialchina.cn">visit@sialchina.cn</a></p>
						<p class="p">Media Partner: <a href="mailto:pr@sialchina.cn">pr@sialchina.cn</a></p>
						<p class="p">Industry Summit: <a href="mailto:nbd@sialchina.cn">nbd@sialchina.cn</a></p>
					</div>
					<div class="foot-unit-left">
						<div class="foot-unit-con">
							<div class="logo-img"><img src="@/assets/images/foot-logo3.png" alt=""></div>
							<div class="unit">
								<p class="foot-tit">SIAL Shanghai Organizer</p>
								<p class="p">Comexposium</p>
								<p class="p">China General Chamber of Commerce</p>
								<p class="p">Comexposium-SIAL Exhibition Co., Ltd</p>
							</div>
							<div class="unit">
								<p class="foot-tit">SIAL Shenzhen Organizer</p>
								<p class="p">Comexposium</p>
								<p class="p">Comexposium-SIAL Exhibition Co., Ltd</p>
							</div>
						</div>
						<div class="foot-tip">* This exhibition is a professional food and beverage trade show, open exclusively to industry professionals. Individuals under 18 and non-professionals over 70 are restricted to enter the venue.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="foot-bottom">
		<div class="wrap clearfloat">
			<p class="copyright">Copyright © {{ curr_year }} Comexposium-SIAL Exhibition Co., Ltd. <span>All rights Reserved <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">京ICP备05075462号-1</a></span></p>
			<p class="jishu">Support By: FlexEvent</p>
		</div>
	</div>
</footer>

</template>

<script setup>
import { ref } from 'vue';

const curr_year = new Date().getFullYear();
</script>

<style lang="less" scoped>
.mb-show {
  display: none !important;
}
.reg-footer {
    background-color: #333333;
    color: #AAAAAA;
    .wrap {
        width: 94%;
        margin: 0 auto;
        max-width: 1500px;
    }
    a {
        color: #AAAAAA;
        &:hover {
            color: @color1;
        }
    }
    .foot-con {
        padding: 60px 0 50px;
        display: flex;
    }
    .foot-logo {
        margin-bottom: 45px;
        img {
            width: 70%;
        }
    }
    .left {
        width: 300px;
        flex-shrink: 0;
        .tit {
            font-weight: bold;
            font-size: 28px;
            color: #fff;
            margin-bottom: 35px;
        }
        .p {
            font-weight: bold;
            margin-top: 25px;
            font-size: 18px;
            color: #aaaaaa;
            display: flex;
            align-items: center;
            img {
                width: 30px;
                flex-shrink: 0; 
                margin-right: 5px;
            }
            a {
                color: #fff;
                text-decoration: underline;
                &:hover {
                    color: @color1;
                }
            }
        }
    }
    .right {
        border-left: 1px solid #AAAAAA;
        padding-left: 45px;
        margin-left: 30px;
        flex-grow: 1;
    }
    .foot-tit {
        color: #fff;
        font-size: 20px;
        margin-bottom: 18px;
        line-height: 1;
        font-weight: bold;
    }
    .foot-links {
        display: flex;
        justify-content: space-between;
        .tit {
            font-weight: bold;
            line-height: 1;
            margin-bottom: 18px;
            color: #fff;
            white-space: nowrap;
        }
        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
        }
        a {
            display: block;
            margin-bottom: 15px;
            line-height: 1;
            position: relative;
            &:last-child {
                margin-bottom: 0;
            }
            .hot {
                position: absolute;
                right: 0;
                top: -15px;
                color: #EA410C;
                font-weight: bold;
            }
        }
    }
    .foot-unit {
        display: flex;
        justify-content: space-between;
        margin: 60px 0 0;
		    flex-direction: row-reverse;
        > div {
            display: inline-block;
            vertical-align: top;
        }
        .foot-unit-left {
          margin-right: 30px;
        }
        .logo-img {
            img {
                width: 120px;
            }
        }
        .unit {
            .p {
                margin-bottom: 15px;
                line-height: 1;
            }
        }
    }
    .foot-unit-con {
      display: flex;
      width: 100%;
      > div {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .foot-unit-left {
      flex-grow: 1;
    }
    .foot-contact {
        color: #fff;
        font-size: 16px;
        width: 500px;
        .p {
            margin-bottom: 15px;
            line-height: 1;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .txt,
        a {
            color: #AAAAAA;
        }
    }
    .foot-ewm {
        .ewm-item {
            text-align: center;

            img {
                width: 100px;
            }
            p {
                margin-top: 5px;
                color: #fff;
                a {
                    text-decoration: underline;
                    color: #fff;
                    &:hover {
                        color: @color1;
                    }
                }
            }
        }
    }
    .foot-bottom {
        padding: 12px 0;
        font-size: 12px;
        background-color: #000;
        .clearfloat;
        .copyright {
            float: left;
        }
        .jishu {
            float: right;
        }
    }
    .foot-tip {
      margin-top: 30px;
    }
}
@media screen and (max-width: 1480px){
    .reg-footer {
        .foot-links li {
            margin-right: 2%;
        }
        .foot-unit > div {
            margin-right: 2%;
        }
        .foot-unit-con > div {
          margin-right: 3%;
        }
        .foot-unit .foot-unit-left {
          margin-right: 3% !important;
        }
    }
}
@media screen and (max-width: 1440px){
    .reg-footer {
        .left {
            .tit {
                font-size: 25px;
            }
            .p {
                font-size: 18px;
                img {
                    width: 27px;
                }
            }
        }
        .foot-links a {
            white-space: normal !important;
        }
        .right {
            padding-left: 30px;
        }
    }
}
@media screen and (max-width: 768px) {
	.mb-hide {
	  display: none !important;
	}
	.mb-show {
	  display: block !important;
	}
	.reg-footer {
		.wrap {
			width: 90%;
		}
		.foot-con {
			display: block;
			padding: 30px 0 20px;
		}
		.foot-logo {
			display: flex;
			margin-bottom: 30px;
			justify-content: center;
			img {
				height: 70px !important;
				width: auto;
				margin-right: 20px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.left {
			width: 100%;
			.tit {
				font-size: 20px;
			}
			.p {
				margin-top: 20px;
				font-size: 16px;
				img {
					width: 25px;
				}
				&.mb-show {
					display: flex !important;
				}
			}
		}
		.right {
			padding-left: 0;
			margin-left: 0;
			border: 0;
		}
    .foot-unit {
        display: block;
      }
      .foot-tip {
        margin-top: 20px;
      }
		.foot-unit {
			margin: 40px 0 30px;
			.logo-img,
			.unit {
				display: none;
			}
			.foot-contact {
				margin-right: 0;
			}
		}
		.foot-links,
		.foot-ewm {
			display: none !important;
		}
		.foot-bottom {
			padding: 13px 0;
			line-height: 1.5;
			.copyright,
			.jishu {
				float: none;
				text-align: center;
			}
			.copyright {
				span {
					display: block;
				}
			}
		}
		
	}
}
</style>