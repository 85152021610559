<template>
  <div class="container-header">
    <div class="head-logo">
        <img src="@/assets/images/logo2.png" alt="">
    </div>

    <div class="head-right">
      <div class="head-nav">
        <router-link :to="`${$t('baseurl')}/Index/index`" class="li">{{$t("meta.首页")}}</router-link>
        <router-link :to="`${$t('baseurl')}/Booking/pairList`" class="li" v-if="$store.getters.show_matchmaking">{{$t("meta.商贸配对")}}</router-link>
        <template v-if="$t('lang') != 'en'">
          <template v-for="item in $store.getters.expo_area_list" :key="item.id">
            <router-link to="/innovation?expo_area_id=1" class="li" v-if="item.id == 1">{{$t("meta.上海创新大赛")}}</router-link>
            <router-link to="/innovation?expo_area_id=2" class="li" v-if="item.id == 2">{{$t("meta.深圳创新大赛")}}</router-link>
          </template>
        </template>
        <!-- <a :href="$t('lang') == 'en' ? 'https://www.sialchina.com/events/steak.html' : 'https://www.sialchina.cn/events/steak.html'" target="_blank" class="li">{{ $t("meta.臻牛奖") }}</a> -->
        <a :href="catalogue_url" target="_blank" class="li">{{ $t("meta.电子会刊") }}</a>
      </div>
      <div class="system-btn">
        <el-space :size="10">
          <a href="https://sialchina.cn/" target="_blank" class="btn gohome">{{$t('返回官网')}}</a>
          <router-link class="linka link-home" :to="$t('baseurl')" :title="$t('meta.首页')"><i class="fa fa-home"></i></router-link>
          <router-link class="linka link-account" :to="`${$t('baseurl')}/Company/info`" :title="$t('meta.会刊信息')"><i class="fa fa-user"></i></router-link>
          <el-button @click="logout" class="linka link-outlog" :title="$t('meta.退出')"><i class="fa fa-sign-out"></i></el-button>

          <div class="menubtn mb-show" :class="{'active' : is_nav}" @click="showMenu">
            <span></span>
            <span></span>
            <span></span>
        </div>
          <!-- <div class="langbtn">
            <a :href="url_cn" @click="toggleLang('cn')" class="a" :class="{curr: locale == 'zh-cn'}">中文 </a>
            <span class="line"></span>
            <a :href="url_en" @click="toggleLang('en')" class="a" :class="{curr: locale == 'en'}">EN </a>
          </div> -->
        </el-space>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive, toRefs, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Cookies from "js-cookie";

export default {
  setup(props, {emit}){
    const state = reactive({
      locale: "zh-cn",
      url_cn: "",
      url_en: "",
      expo_area_list: [],
      is_nav: false,
      catalogue_url: ""
    })
    const { t, locale } = useI18n();
    const router = useRouter();
    const store = useStore();

    state.locale = locale.value;

    if(state.locale == 'en') {
      state.catalogue_url = `https://catalogue.sialchina.cn/en/siglelogin?account=${Cookies.get("account")}&token_login=${Cookies.get("token_login")}`
    }else {
      state.catalogue_url = `https://catalogue.sialchina.cn/siglelogin?account=${Cookies.get("account")}&token_login=${Cookies.get("token_login")}`
    }

    const logout = ()=>{
      store.dispatch("user/logout").then(()=>{
        router.push(`${t("baseurl")}/login`);
      });
    }

    const toggleLang = (lang)=>{
      if(lang == "en") {
        state.url_en = process.env.VUE_APP_EN_HOST + window.location.pathname.replace(/^(\/en)/,"");

      }else {
        state.url_cn = process.env.VUE_APP_HOST + window.location.pathname.replace(/^(\/en)/,"");
      }
    }

    const showMenu = ()=>{
      state.is_nav = !state.is_nav;
      emit("showMenu",state.is_nav);
    }

    watch(() => router.currentRoute.value,()=>{
      state.is_nav = false,
      emit("showMenu",state.is_nav);
    })

    return {
      ...toRefs(state),
      logout,
      toggleLang,
      showMenu
    }
  }
};
</script>



<style lang="less" scoped>
.container-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: #000000;
  height: @headtopHeight;
  display: flex;
  align-items: center;
  .head-logo {
    flex-shrink: 0;
    width: @leftWidth;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    img {
      max-width: 70%;
      max-height: 80%;
    }
  }
  .head-right {
    flex-grow: 1;
    padding: 0 @spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .head-nav {
    display: flex;
    align-items: center;
    color: #fff;
    .li {
      font-size: 16px;
      padding: 0 30px;
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      color: #fff;
      &:hover {
        color: @color1;
      }
    }
  }
  .system-btn {
      padding: 32px 0;
      margin-left: auto;
      .gohome {
        display: none;
      }
      .linka {
          display: inline-block;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          line-height: 34px;
          text-align: center;
          font-size: 18px;
          border: 0;
          padding: 0;
          vertical-align: middle;
          .transition(0.2s);
      }
      .linka.link-home {
          background-color: rgba(230, 165, 19, 0.1);
          color: #e6a513;
      }
      .linka.link-account {
          background-color: rgba(207, 95, 90, 0.1);
          color: #cf5f5a;
      }
      .linka.link-outlog {
          background-color: rgba(255, 255, 255, 0.1);
          color: #fff;
      }
  }

  .langbtn {
    display: inline-block;
    vertical-align: top;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px 15px;
    white-space: nowrap;
    .a {
      &.curr,
      &:hover {
        color: @color2;
      }
    }
    .line {
      height: 11px;
      width: 1px;
      background: @color1;
      opacity: 0.5;
      margin: 0 7px;
      display: inline-block;
    }
  }
  :deep .el-space__item:last-child {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .container-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 20;
    background-color: @color1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 40px;
    z-index: @headZindex;
    .head-logo {
      height: 40px;
      line-height: 40px;
      width: auto;
      flex-shrink: 0;
      img {
        max-height: 30px;
      }
    }
    .head-right {
      width: auto;
      flex-grow: 1;
      padding: 0 20px;
      .head-nav {
        display: none;
      }

      .system-btn {
        padding: 0;
        .linka {
          display: none;
        }
        :deep .el-space__item {
          margin-right: 0 !important;
        }
        .gohome {
          display: block;
          background-color: @color2;
          border-radius: 5px;
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          margin-right: 10px;
        }
        .langbtn {
          height: 30px;
          line-height: 30px;
        }
      }
      
    }
  }

  .menubtn{
    display: inline-block !important;
    width:28px;
    cursor:pointer;
    z-index: 3;
    padding: 5px 0;
    span{
        display:block;
        width:100%;
        height:2px;
        background:#fff;
        border-radius:4px;
        margin-top:7px;
        -webkit-transition: 400ms;
        -moz-transition: 400ms;
        -ms-transition: 400ms;
        -o-transition: 400ms;
        transition: 400ms;
        &:first-of-type{
            margin-top:0;
        }
    }
    &.active{
        span{
            -webkit-transition: 400ms;
            -moz-transition: 400ms;
            -ms-transition: 400ms;
            -o-transition: 400ms;
            transition: 400ms;
            &:nth-of-type(1){
                transform: rotate(45deg) translate(6px,6px);
            }
            &:nth-of-type(2){
                opacity: 0;
            }
            &:nth-of-type(3){
                transform: rotate(-45deg) translate(6px,-6px);
            }
        }
    }
  }
}
</style>
