import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import i18n from '@/locales/i18n';
import store from "@/store"

const t = i18n.global.t;
let currentLocale = "cn";
if (window.location.pathname.indexOf("/en") == 0 || window.location.hostname == "exhibitor.sialchina.com") {
  currentLocale = "en"
}

const instance = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: `${currentLocale == "en" ? process.env.VUE_APP_API_EN_URL : process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_AXIOS}`,
  // 设置请求超时时间
  timeout: 5*60*1000,
});

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      config.headers["exhibitor-token"] = store.getters.token;
    }
    return config;
  },
  (error) => {
    // 请求错误的统一处理
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if(res.code == undefined) { 
      return res;
    }
    if(res.code === 200 || res.code === 1) {
      return res;
    }else {
      if (res.code === 4001) {
        // 重新登录
        ElMessageBox.alert(t("log in first"), {
          confirmButtonText: t("Log back in"),
          type: "warning",
          "show-close": false,
        })
        .then(() => {
          store.dispatch("user/logout").then(() => {
            window.location.href=`${t("baseurl")}/login`
          });
        });
      }
      return Promise.reject(res);
      
    }
  },
  (error) => {
    const { response } = error;
    console.log("request-err" , error, response); // for debug
    if(response && response.status == 408) {
      ElMessage({
        message: t("请求超时，请刷新后重试"),
        type: "error",
        duration: 5 * 1000,
      });
    }else {
      ElMessage({
        message: error.message || error,
        type: "error",
        duration: 5 * 1000,
      });
    }
    
    return Promise.reject(error);
  }
);

export default instance;