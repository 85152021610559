<template>
  <div>
    <h1 v-if="isIE">请在其他浏览器中打开本网站</h1>

    <el-config-provider v-else :locale="configLocale">
      <div>
      <router-view></router-view>
      </div>
    </el-config-provider>
  </div>
</template>

<script>
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";
import { useI18n } from "vue-i18n";
import { reactive, ref, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { tongjiHeadEn } from "@/utils/tongji";

export default {
  name: "App",
  setup(){
    const router = useRouter();
    const state = reactive({
      isIE: false,
    })

    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      }
    }
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    }

    const { t, locale } = useI18n();

    const configLocale = ref(null);
    if (locale.value == "en") {
      configLocale.value = en;
    } else {
      configLocale.value = zhCn;
    }

    onMounted(()=>{
      document.title = t("head_title");

      const ua = navigator.userAgent;
      if (ua.includes('MSIE') || ua.includes('Trident')) {
        state.isIE = true;
      }
      console.log("00isIE",state.isIE);
    })

    // 添加统计代码
    router.afterEach( ( to, from, next ) => {
      if(process.env.NODE_ENV == "production") {
        if(locale.value == "en") {
          tongjiHeadEn();
          if (window._hmt) {
            if (to.path) {
              window._hmt.push(['_trackPageview', to.fullPath])
            }
          }
        }else {
          if (window._hmt) {
            if (to.path) {
              window._hmt.push(['_trackPageview', to.fullPath])
            }
          }
        }
      }
    } );

    return {
      ...toRefs(state),
      configLocale,
    };
  }
};
</script>
