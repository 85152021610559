import {createStore} from 'vuex'
import user from "./modules/user";
import Cookies from "js-cookie";

let currentLocale = "cn";
if (window.location.pathname.indexOf("/en") == 0 || window.location.hostname == "exhibitor.sialchina.com") {
  currentLocale = "en"
}


const store = createStore({
    state: {
        sh_domain: "https://www.sialchina.cn/",
        sz_domain: "https://www.sialshenzhen.com/",
        home_domain: currentLocale =="en" ? "https://www.sialchina.com/" : "https://www.sialchina.cn/",
        visitor_domain: currentLocale =="en" ? "https://visitor.sialchina.com" : "https://visitor.sialchina.cn",
        qrcode_origin: "https://visitor.sialchina.cn/api/getQrcode?text=",
        domain: process.env.VUE_APP_HOST,
        domain_en: process.env.VUE_APP_EN_HOST,
        api_domain: currentLocale =="en" ? process.env.VUE_APP_API_EN_URL : process.env.VUE_APP_API_URL,
        is_now_expoid: [1],
        notshow_expoid: [2],
    },
    modules: {
        user,
    },
    getters: {
        roles: state => state.user.roles,
        token: state => state.user.token,
        routers: state => state.user.routers,
        expo_area_list: state => state.user.expo_area_list,//获取当届展区
        verifyInfo: state => state.user.verifyInfo,
        show_matchmaking: state => state.user.show_matchmaking,
    },
    mutations: {
    },
})

export default store