import { createI18n } from "vue-i18n";
import en from "./lang/en";
import cn from "./lang/cn";


function getLanguage() {
  // 检查域名以确定语言
  if (window.location.hostname.endsWith('.com') || window.location.pathname.indexOf("/en") == 0) {
    return 'en';
  } else {
    return 'zh-cn'; 
  }
}


const i18n = createI18n({
  legacy: false,
  locale: getLanguage(),  // 语言标识（缓存里面没有就用中文）
  fallbackLocale: "zh-cn", //没有英文的时候默认中文语言
  globalInjection: true, // 全局注册$t方法
  messages: {
    "zh-cn": cn,
    en,
  },
});

export default i18n;
