import Cookies from "js-cookie";

const TokenKey = "token";
const isLogin = "isLogin";

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getLoginStatus() {
  return Cookies.get(isLogin);
}

export function setLoginStatus(data) {
  return Cookies.set(isLogin, data);
}

export function removeLoginStatus() {
  return Cookies.remove(isLogin);
}