//cn.js
const cn = {
    "baseurl": "",
    "vst_baseurl": "",
    "lang": "cn",
    "head_title": "SIAL 西雅国际食品和饮料展览会展商中心-食品展|美食展",
    "sh_expo_time": "2025年05月19日-21日",
    "sz_expo_time": "2025年09月01日-03日",
    "meta": {
        "首页": "首页",
        "会员信息": "会员信息",
        "基本信息": "基本信息",
        "联系人": "联系人",
        "修改密码": "修改密码",
        "参展记录": "参展记录",
        "上海展": "上海展",
        "深圳展": "深圳展",
        "展位详情": "展位详情",
        "展位预定": "展位预定",
        "展示名录": "展示名录",
        "参展管理": "参展管理",
        "团队成员": "团队成员",
        "团队成员信息": "团队成员信息",
        "会刊信息": "会刊信息",
        "企业logo": "企业logo",
        "展品信息": "展品信息",
        "展会相关": "展会相关",
        "宣传服务": "宣传服务",
        "新闻详情": "新闻详情",
        "联名广告": "联名广告",
        "商贸配对": "商贸配对",
        "预约观众": "预约观众",
        "查看检索历史": "查看检索历史",
        "我的收藏": "我的收藏",
        "预约管理": "预约管理",
        "收到的约见": "收到的约见",
        "发起的约见": "发起的约见",
        "我的日程": "我的日程",
        "食品产业峰会": "食品产业峰会",
        "线下峰会": "线下峰会",
        "峰会直播": "峰会直播",
        "峰会大咖": "峰会大咖",
        "主场搭建": "主场搭建",
        "客户邀请": "客户邀请",
        "电子邀请": "电子邀请",
        "海报邀请": "海报邀请",
        "下载中心": "下载中心",
        "赞助意向": "赞助意向",
        "签证邀请函": "签证邀请函",
        "信息订阅": "信息订阅",
        "SIAL创新大赛": "SIAL创新大赛",
        "上海创新大赛": "上海创新大赛",
        "深圳创新大赛": "深圳创新大赛",
        "参展商证件": "参展商证件",
        "退出": "退出",
        "专属广告": "专属广告",
        "通用横幅广告": "通用横幅广告",
        "展会标志": "展会标志",
        "更多广告机会": "更多广告机会",
        "查找观众": "查找观众",
        "查看我的收藏": "查看我的收藏",
        "臻牛奖": "SIAL 臻牛奖",
        "电子会刊": "电子会刊",
        "联系主办方": "联系主办方",
    },
    "footer": {
        "copyright": `Copyright © ${new Date().getFullYear()} 北京爱博西雅展览有限公司 版权所有`,
        "技术提供": "技术提供：FlexEvents",
        "北京爱博西雅展览有限公司": "北京爱博西雅展览有限公司",
        "上海展会主办单位": "上海展会主办单位",
        "深圳展会主办单位": "深圳展会主办单位",
        "中国商业联合会": "中国商业联合会",
        "总部地址": "总部地址",
        "联系电话": "联系电话",
        "address": "北京市朝阳区朝阳门外大街22号泛利大厦1401室",
        "联系方式": "联系方式",
        "参展咨询": "参展咨询",
        "参观咨询": "参观咨询",
        "媒体合作": "媒体合作",
        "论坛会议及活动": "论坛会议及活动",
        "微信订阅号": "微信订阅号",
        "微信服务号": "微信服务号",
        "SIAL在线": "SIAL在线",
        "小红书": "小红书",
        "stipulation_tip": "温馨提示：本展会为专业食品饮料展，仅对专业人士开放，不满18周岁未成年人和70周岁及以上的非专业人士无法入场。",
        "phone": "010-8572 8461",
    },
    "返回官网": "返回官网",
    "date format": "Y年M月D日",
    "exhibitor title": "展商会员中心",
    "SIAL in China": "SIAL 世界三大食品展之一",
    "sial title": "SIAL 西雅国际食品和饮料展览会",
    "填写公司信息": "填写公司信息",
    "注册成功": "注册成功",
    "input account": "请使用账号登录",
    "input password": "请输入密码",
    "remember password": "记住密码",
    "login": "立即登录",
    "reg": "立即注册",
    "tip": "提示",
    "The login user name and password shall be provided by the organizer.": "展商可以凭借主办方提供的用户名和密码登录",
    "If you have any questions, please contact your sales.": "登录如有问题请与您的销售联系",
    "shanghai introduction": "SIAL西雅展(上海)将于2025年5月19日-21日在上海新国际博览中心举办，展览面积将达200,000㎡，预计吸引全球75+国家和地区的5,000+展商和全球110+国家和地区的180,000+专业人士。同期还将举办SIAL世界食品产业峰会和赛事及活动。",
    "shenzhen introduction": "SIAL 西雅展(深圳)将于2025年9月1日-3日在深圳会展中心(福田)举办，展览面积将达到60,000㎡，预计吸引全球50个国家和地区的1,500+展商和67,000位专业人士，同期还将举办SIAL世界食品产业峰会和赛事及活动。",
    "official website": "进入官网首页",
    "browser recommended": "为了便于您的阅读，建议使用浏览器: 360 极速模式、Microsoft Edge、 Google 60、Firefox 60以上版本访问该系统。",
    "welcome": "您好,欢迎您登录",
    "Guide": "操作指引",
    "You can complete your company's exhibition information through the following guidelines": "非常重要！完成以下四个步骤！",
    "Step 1": "步骤一",
    "Catalogue": "我的会刊信息",
    "no catalogue tips": " 请先进行本届会刊资料填写！",
    "Step 2": "步骤二",
    "Products": "我的展品信息",
    "add product": "新增展品信息",
    "my products": "我的展品库",
    "uploaded": "已上传",
    "invited": "已邀请",
    "Step 3": "步骤三",
    "Invite Customers": "通知客户",
    "add invite": "新增客户邀请",
    "Step 4": "步骤四",
    "Exhibitor Badge": "申请展商胸卡",
    "add badge": "新增参展商证件",
    "company": "公司",
    "edit info": "编辑信息",
    "venus": "展馆",
    "馆": "馆",
    "号": "号",
    "Booth No": "展位号",
    "Booth Type": "展位类型",
    "Square": "展位面积",
    "hall": "展馆号",
    "aisle": "通道号",
    "No booth has been allocated": "暂未分配展位",
    "no data": "暂无数据",
    "sial exhibition arrangement": "SIAL 西雅国际食品和饮料展览会全年展会安排",
    "szManualFile": "https://imgs.sialchina.cn/download/20240729/97bb3c491e6e9fa183f6fcc34d46579066a7090f3f5aa.pdf",
    "szManualTittle": "SIAL Shenzhen 2024西雅展参展商手册.pdf",
    "close manual tip": "2025参展商手册暂未开放，敬请期待！",
    "Exhibition hall plan": "展馆平面图",
    "Exhibitor Manual": "参展商手册",
    "booth booking": "预定展位",
    "please input": "请输入",
    "please select": "请选择",
    "please upload": "请上传",
    "your": "您的",
    "a valid": "有效的",
    "change info, please contact sial": "账号相关的联系人信息如需修改，请联系SIAL 西雅国际食品和饮料展览会主办方",
    "password format": "8~12位密码，可以包含数字、大写字母、小写字母或符号",
    "two password is defferent": "新密码与确认密码不一致",
    "change password success": "更新密码成功",
    "tips": "温馨提示",
    "notice": "注意",
    "add": "增加",
    "sort": "排序",
    "info": {
        "company info": "公司信息",
        "账号名称": "账号名称",
        "contacts info": "联系人信息",
        "catalogue name": "公司名称",
        "company": "公司名称",
        "short langCn": "（中文）",
        "short langEn": "（英文）",
        "long langEn": "（英文）",
        "long langEn2": "（英文）",
        "long langCn": "（中文）",
        "select langCn": "中文",
        "select langEn": "英文",
        "country": "国家/地区",
        "china": "中国大陆",
        "area": "省市",
        "province": "省份",
        "city": "城市",
        "address": "公司地址",
        "truename": "姓名",
        "mobile": "手机",
        "email": "邮箱",
        "company email": "公司邮箱",
        "password": "密码",
        "old password": "原密码",
        "new password": "新密码",
        "confirm password": "确认新密码",
        "Business Scope": "公司性质",
        "website": "公司网址",
        "Company Introduction": "公司介绍",
        "Products Categories": "产品类别",
        "Theme Exhibitions": "主题展区",
        "Brand information": "品牌信息",
        "Brand Name": "品牌名称",
        "Brand Website": "品牌网站",
        "Brand Description": "品牌描述",
        "Current exportation": "目标市场区域",
        "Find Agents": "招代理区域",
        "Your Target Markets": "您的目标客户",
        "Recommanded Trip": "热门主题线路",
        "product infomation": "展品信息",
        "product name": "展品名称",
        "is new": "是否新产品",
        "is extend": "是否需要主办方推广该产品",
        "lightspot": "亮点",
        "place of origin": "产地",
        "cover image": "产品封面图",
        "product image": "产品详情图",
        "video link": "视频链接",
        "news link": "新闻链接",
        "product introduction": "产品介绍",
        "Storage Conditions": "贮存条件",
        "product category": "展品分类",
        "select exhibition": "选择展会",
        "yes": "是",
        "no": "否",
        "news title": "新闻标题",
        "desc": "摘要",
        "news content": "内容",
        "submit": "提交",
        "amost 10": "最多可选10项",
        "amost 4": "最多可选4项",
        "amost": "最多可选",
        "account": "账号",
        "submit success": "提交成功",
        "update success": "更新成功",
        "subscribe lang": "订阅语言",
        "subscribe type": "订阅类型",
        "Products of Interest": "感兴趣的产品",
        "展会快讯": "展会快讯",
        "行业资讯": "行业资讯",
        "新品推荐": "新品推荐",
        "活动推介": "活动推介",
        "展商介绍": "展商介绍",
        "exhibit image": "展品图片",
        "exhibit introduction": "展品介绍",
        "Business type": "业务类型",
        "观众公司名称": "观众公司名称",
        "观众姓名": "观众姓名",
        "观众职位": "观众职位",
        "业务范围": "业务范围",
        "感兴趣展品": "感兴趣展品",
        "预约时间": "预约时间",
        "备注": "备注",
        "上午": "上午",
        "下午": "下午",
        "展区": "展区",
        "受邀公司名称": "受邀公司名称",
        "受邀人姓名": "受邀人姓名",
        "买家姓名": "买家姓名",
        "联系人": "联系人",
        "登录账号": "登录账号",
        "登录密码": "登录密码",
        "订阅展会": "订阅展会",
        "兴趣主题": "兴趣主题",
        "catalogue tips": "公司名称将展示在电子会刊、在线及现场展商名录中。",
    },
    "example": "例",
    "status": "状态",
    "all": "所有",
    "session": "届数",
    "exhibition": "展会",
    "exhibition name": "展会名称",
    "operate": "操作",
    "booth detail": "展位详情",
    "Not exhibited": "未参展",
    "Already exhibited": "已参展",
    "back": "返回",
    "delete": "删除",
    "publish time": "发布时间",
    "create time": "创建时间",
    "time": "时间",
    "edit": "编辑",
    "view": "查看",
    "browse": "浏览",
    "search": "搜索",
    "introduction": "查看介绍",
    "are your sure delete": "确定删除吗？",
    "Successfully deleted": "删除成功！",
    "check at least one": "您至少需要勾选一项",
    "Permission node": "权限节点",
    "confirm": "确定",
    "cancel": "取消",
    "在线展商名录": "在线展商名录",
    "我的展商名录": "我的展商名录",
    "以下信息将用于展商名录展示，点击查看": "以下信息将用于展商名录展示，点击查看",
    "format": "格式",
    "size": "大小",
    "Recommended size": "建议尺寸",
    "image size width": "",
    "drag file": "将文件拖至此处或点击选择",
    "file upload": "选择文件上传",
    "log in first": "请先登录",
    "Log back in": "重新登录",
    "请求超时，请刷新后重试": "请求超时，请刷新后重试",
    "参数有误，请刷新后重试": "参数有误，请刷新后重试",
    "start time": "开始时间",
    "end time": "结束时间",
    "to": "至",
    "type": "类型",
    "download": "下载",
    "more": "查看详情",
    "image": "图片",
    "activitive intro": "活动介绍",
    "activitive address": "活动地址",
    "Please select year, month": "请选择年、月份",
    "Copy": "点击复制",
    "Method One": "方法一",
    "Method Two": "方法二",
    "Language": "语言",
    "Language vension": "语言版本",
    "请输入好友信息": "请输入好友信息",
    "展商单次邀请观众邮箱数量不能超过50个。": "展商单次邀请观众邮箱数量不能超过50个。",
    "邀请多个观众时，请每行填写一个观众邮箱。": "邀请多个观众时，请每行填写一个观众邮箱。",
    "推荐好友成功！": "推荐好友成功！",
    "复制成功，赶快分享给好友吧": "复制成功，赶快分享给好友吧",
    "One mailbox per line": "一行一个邮箱",
    "E-mail invitation Template": "邮件邀请模板",
    "For more information about SIAL in China, please fill in the information below to subscribe": "如需了解 SIAL 西雅国际食品和饮料展览会更多资讯，请填写以下信息及时订阅！",
    "template": "模板",
    "select the template and get customized invitation letter": "请在下方选择您喜欢的模板，然后生成邀请函",
    "Generate invitation": "生成邀请函",
    "您可通过微信扫码保存至手机或直接下载邀请函发送给客户！返回可重新生成邀请函。": "您可通过微信扫码保存至手机或直接下载邀请函发送给客户！返回可重新生成邀请函。",
    "back to edit": "重新编辑",
    "download invitation": "下载邀请函",
    "请填写完信息": "请填写完信息",
    "生成横幅广告": "生成横幅广告",
    "下载所有广告": "下载所有广告",
    "Not interested": "不感兴趣",
    "Cancel Collection": "取消收藏",
    "Collection": "收藏",
    "Appointment": "预约",
    "Appointmented": "已预约",
    "Cancel Collection success": "成功取消收藏",
    "Collection success": "收藏成功",
    "You have reserved this visitor, please wait patiently for the reply": "您已预约该观众，请耐心等待回复",
    "Please select an appointment time": "请选择预约时间",
    "您的预约信息已经提交，请耐心等待回复": "您的预约信息已经提交，请耐心等待回复",
    "接受": "接受",
    "拒绝": "拒绝",
    "确定拒绝约见吗？": "确定拒绝约见吗？",
    "成功拒绝": "成功拒绝",
    "同意约见": "同意约见",
    "新增展品信息成功": "新增展品信息成功",
    "add news": "新增展商新闻",
    "以下联系人信息仅作联系使用（例如：接收系统通知），将不会对外展示": "以下联系人信息仅作联系使用（例如：接收系统通知），将不会对外展示！",
    "Product sorting tips": "您可以通过列表的“排序”功能，对添加的展品进行排序，作为贵司在“展商名录-展商详情”的产品展示顺序排序，数值越大，排序越前。",
    "teamstaff tips": "如您需要团队里其他成员协助管理贵公司的参展信息如：会刊、展品、新闻等，请按系统提示创建团队管理员登录账号及选择所属权限组。",
    "Click on each banner to preview them": "单击每个横幅广告可以逐个预览，获取相应的集成代码或将它们保存到您的计算机。",
    "You are already an member": "您已经成功注册为SIAL 西雅国际食品和饮料展览会的会员",
    "Enter Exhibitor Member Center": "进入展商会员中心",
    "use account to log in member center to view information": "请使用以下账号登录会员中心查看SIAL 西雅国际食品和饮料展览会的相关信息。",
    "Opening soon": "即将开放，请耐心等待！",
    "请重新登录": "请重新登录",
    "username": "用户名",
    "请先完成会刊信息": "请先进行本届会刊资料填写！",
    "请先上传展品信息": "请先上传展品信息！",
    "只允许输入文字内容，不可上传图片": "只允许输入文字内容，不可上传图片",
    "invite_recommend_tip": "使用以下功能，可邀请客户提前预登记，现场享快速入场观展，避免拥挤和排队。",
    "exhibit room": "往届展品库",
    "exhibit room tips": "如需将贵司产品展示在展品名录里，请对应展会(上海展/深圳展)下开启该产品的显示状态。",
    "not booth num": "暂无展位号",
    "create banner": "生成横幅广告中...",
    "event schedule": "参展商重要事项提醒",
    "event schedule tips": "请务必在以下截止之日前操作",
    "contact": {
        'name': "联系人：",
        'email': "邮箱：",
        'phone': "座机：",
        'mobile': "手机：",
    },
}
 
export default cn