import { createRouter, createWebHistory } from 'vue-router'
import store from "@/store";
import showMsg from "@/utils/message";
import i18n from '@/locales/i18n';

import Layout from "@/views/Layout/Index.vue";

import Login from '@/views/Auth/login.vue'
import Register from '@/views/Auth/reg.vue'


const t = i18n.global.t;
let prefix = "";
if (window.location.pathname.indexOf("/en") == 0) {
  prefix = "/en";
}

const routes = [
  { path: `${prefix}/login`, name: `login${prefix}`, component: Login, hidden: true },
  { path: `${prefix}/reg`, name: `reg${prefix}`, component: Register, hidden: true },
  // 法国单点登录
  { path: "/french_ssoLogin", name: "french_ssoLogin", component: () => import("@/views/Auth/frenceLogin.vue"), hidden: true },
  {
    path: prefix,
    component: Layout,
    children: [
      {
        name: "",
        path: prefix == "" ? "/" : prefix,
        component: () => import("@/views/Index/index.vue"),
        meta: { title: t("meta.首页"), icon: "icon-shouye" },
        redirect: `${prefix}/Index/index`
      },
      {
        name: "",
        path: "",
        component: () => import("@/views/Index/index.vue"),
        meta: { title: t("meta.首页"), icon: "icon-shouye" },
        redirect: `${prefix}/Index/index`
      },
    ]
  },
  {
    path: prefix,
    component: Layout,
    children: [
      // 参展商证件
      {
        name: t("meta.参展商证件"),
        path: "Badges/badgesEmbedded",
        component: () => import("@/views/ExhibitionManagement/badges.vue"),
        meta: { title: t("meta.参展商证件")},
        hidden: true
      },

      // 团队成员
      {
        name: `CompanyEdit${prefix}`,
        path: "Company/edit/:id(\\d+)",
        component: () => import("@/views/TeamStaff/edit.vue"),
        meta: { title: t("meta.团队成员信息")},
        hidden: true
      },

      // 展品
      {
        name: `productEdit${prefix}`,
        path: "Product/productEdit/:id(\\d+)",
        component: () => import("@/views/ExhibitionManagement/Product/edit.vue"),
        meta: { title: t("meta.展品信息")},
      },

      // 参展记录
      {
        path: "Exhibitor/records",
        component: () => import("@/views/ExhibitionRecord/index.vue"),
        name: `ExhibitionRecord${prefix}`,
        meta: { title: t("meta.参展记录"),},
      },
      {
        path: "Exhibitor/booth/:expo_area_id(\\d+)/:expo_id(\\d+)",
        component: () => import("@/views/ExhibitionRecord/booth.vue"),
        name: `booth${prefix}`,
        meta: { title: t("meta.展位详情")},
      },
      {
        path: "Exhibitor/booth-appy/:expo_area_id(\\d+)",
        component: () => import("@/views/ExhibitionRecord/boothList.vue"),
        name: `boothList${prefix}`,
        meta: { title: t("meta.展位预定")},
      },
      
      // 展示名录
      {
        path: "OnlineHall/oldRecords",
        component: () => import("@/views/OldRecords/profile.vue"),
        name: `profile${prefix}`,
        meta: { title: t("meta.展示名录")},
      },


      // 商贸配对
      {
        path: "Booking/pairHistory",
        component: () => import("@/views/Matchmaking/history.vue"),
        name: `history${prefix}`,
        meta: { title: t("meta.查看检索历史")},
      },
      {
        path: `Booking/favlist`,
        component: () => import("@/views/Matchmaking/favlist.vue"),
        name: `favlist${prefix}`,
        meta: { title: t("meta.我的收藏")},
      },
      {
        path: "receive-booking",
        component: () => import("@/views/Matchmaking/receiveBooking.vue"),
        name: `receiveBooking${prefix}`,
        meta: { title: t("meta.收到的约见")},
      },
      {
        path: "Booking/schedule",
        component: () => import("@/views/Matchmaking/schedule.vue"),
        name: `schedule${prefix}`,
        meta: { title: t("meta.我的日程")},
      },

      // 新闻
      {
        name: `servicesEdit${prefix}`,
        path: 'services/edit/:id(\\d+)',
        component: () => import("@/views/related/services/edit.vue"),
      },

      // 签证
      // {
      //   name: `visa${prefix}`,
      //   path: '/en/Visa/index',
      //   component: () => import("@/views/Visa/index.vue"),
      //   meta: { title: "Invitation Letter", icon: "icon-shouye" },
      // },
      {
        name: "",
        path: '/Visa/info/:id(\\d+)',
        component: () => import("@/views/Visa/info.vue"),
        meta: { title: "Invitation Letter"},
      },
      {
        name: "",
        path: '/Visa/add',
        component: () => import("@/views/Visa/info.vue"),
        meta: { title: "Invitation Letter"},
      },
      {
        name: "",
        path: '/en/Visa/info/:id(\\d+)',
        component: () => import("@/views/Visa/info.vue"),
        meta: { title: "Invitation Letter"},
      },
      {
        name: "",
        path: '/en/Visa/add',
        component: () => import("@/views/Visa/info.vue"),
        meta: { title: "Invitation Letter"},
      },

      // 创新大赛
      {
        name: "",
        path: "/innovation",
        component: () => import("@/views/Innovation/index.vue"),
        meta: { title: t("meta.SIAL创新大赛")},
      }
    ]
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

// 白名单
const whiteList = ["/french_ssoLogin", "/login","/reg","/reg/finish","/en/login","/en/reg","/en/reg/finish",];
const toIndex = ["/login","/reg","/en/login","/en/reg"];

// 添加权限路由
router.beforeEach(async (to, from, next) => {
  const path_home = to.path.indexOf("/en") >= 0 ? "/en/Index/index" : "/Index/index"
  const path_login = to.path.indexOf("/en") >= 0 ? `/en/login?` : "/login"

  // 用户是否登录
  const hasToken = store.getters.token;
  if (hasToken) {
    //已登录
    if (toIndex.includes(to.path)) {
      // 重定向至首页
      next({ path: path_home });
    } else {
      const hasRouters = store.getters.routers && store.getters.routers.length > 0;
      console.log("是否加载路由", store.getters.routers, hasRouters);
      if(hasRouters){
        next();
      }else {
        try {
          await store.dispatch("user/generateRoutes");
        //   添加路由
          next({ ...to, replace: true });
        } catch (error) {
          // 重新登录
          console.log("catch",error);
          showMsg.error(t("请重新登录"));
          await store.dispatch("user/logout");
          if (whiteList.includes(to.path)) {
            next({ path: path_login, query: to.query });
          }else {
            next({path: path_login, query: { redirect: to.path }});
          }
          
        }
      }
    }
  } else {
    // 未登录
    console.log("未登录----------");
    if (whiteList.includes(to.path)) {
      next();
    } else {
        next({ path: path_login, query: { redirect: to.path } });
    }
  }
});


export default router