<template>
  <div class="bg">
    <Header @showMenu="showMenu"></Header>

    <div class="container">

      <Sidebar class="sidebar-container" :class="{'active':show_nav}">
      </Sidebar>

      <div class="container-con" id="mainContainer">
        <!-- 面包屑 -->
        <breadcrumb class="breadcrumb-container" />

        <!-- 内容区 -->
        <AppMain></AppMain>

        <!-- 底部 -->
        <Footer></Footer>
      </div>
    </div>

    <!-- 邀请观众弹窗 -->
    <Invitevst :isShow="isShowInvitevst" @close="isShowInvitevst = false"></Invitevst>
  </div>
</template>


<script>
import { toRefs, reactive, defineComponent, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import showMsg from "@/utils/message";

import { getVerifyInfo } from "@/api/common";

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Sidebar from "./components/Sidebar/Index.vue";
import Breadcrumb from "./components/Breadcrumb/index.vue";
import AppMain from "./components/AppMain.vue";
import Invitevst from "./components/Invitevst.vue";
import Cookies from "js-cookie";

export default defineComponent ({
  components: {
    Header,
    Footer,
    Sidebar,
    Breadcrumb,
    AppMain,
    Invitevst
  }, 
  beforeRouteEnter(to, from, next) {
    // A跳转到B，B页面停留在A页面的滚动位置；解决方法：将scrollTop设置为0
    window.scroll(0, 0);
    next();
  },
  setup() {
    const { t, locale } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive({
      show_nav: false,
      dialogCertVisible: false,
      dialogSurveyVisible: false,
      isShowInvitevst: false,
    })

    const showMenu = (status)=>{
      state.show_nav = status;
    }

    watch(() => router.currentRoute.value,(newVal)=>{
      if(store.getters.roles != 5) {
        verifyStatus(newVal);
      }
    })

    // 会刊，展品未完成状态-非国际展商
    console.log("store.getters.roles",store.getters.roles);
    if(store.getters.roles != 5) {
      verifyStatus(route);
    }
    
    async function verifyStatus(routeData) { 
      var verifyInfo = store.getters.verifyInfo;
      let verify_count = 0;
      let is_exhibitor_count = 0;
      if(!verifyInfo || verifyInfo.length == 0) {
        // getVerifyInfo 获取当届展会信息
        await getVerifyInfo({app_lang: locale.value}).then((res)=>{
          const { data } = res;
          store.commit("user/SET_VERIFYINFO", data);
          verifyInfo = data;
        }).catch(()=>{})
      }
      
      for(let i = 0, len = verifyInfo.length; i < len; i++) {
        // is_exhibitor: 当届是否有会刊数据  company_point: 会刊数据完成度
        const o = verifyInfo[i];
        if(o.is_exhibitor == 1) {
          // 当届
          is_exhibitor_count ++;
          // is_catalogue：是否已上传会刊数据
          if((o.company_point < 100 || o.is_catalogue != 1) && routeData.meta.title != t("meta.会刊信息") && routeData.meta.title != t("meta.首页")) {
            // 会刊信息
            showMsg.warning(t("请先完成会刊信息"));
            router.push(`${t("baseurl")}/Exhibitor/info?expo_area_id=${o.expo_area_id}`);
            break;
          }
          if(o.product_count == 0 && routeData.meta.title != t("meta.会刊信息") && routeData.meta.title != t("meta.展品信息")){
            // 展品信息
            showMsg.warning(t("请先上传展品信息"));
            router.push(`${t("baseurl")}/Product/productList?ispro=1`);
            break;
          }
          if(o.company_point >= 100 && o.product_count > 0) {
            verify_count ++;
          }
        }
      }
    }
    

    return {
      ...toRefs(state),
      showMenu,
    }
  },

});
</script>