<template>
  <el-dialog v-model="dialogVisible" width="900" close-icon="CircleCloseFilled" :close-on-click-modal="false" class="invitevst-pop" :before-close="closeDialog">
    <div class="pop-con">
        <p class="tit"><strong class="strong">邀请客户提前报名SIAL西雅展（深圳）</strong></p>
        
        <p class="tit tit1" style="margin-bottom: 10px;">现场可快速入场参观，避免排队</p>

        <div class="img"><img src="https://imgs.sialchina.cn/uploads/editor/images/20240712/49443442887f1d5e9735fef0d12e6a47.png" alt=""></div>

        <!-- <p class="tit fcbase"><strong class="strong"></strong>2024年9月2日-4日</p> -->
        <div class="btnbox">
            <a href="javascript:;" @click="copyUrl(invite_url)" class="btn js-badge"><i class="iconfont icon-edit3-fill"></i> 点击复制邀请链接 邀请客户</a>
        </div>
    </div>
  </el-dialog>
</template>

<script setup>
import showMsg from "@/utils/message";
import { ref, computed, defineProps, defineEmits } from "vue";

const props = defineProps({isShow: Boolean})
const emit = defineEmits(['close'])
const dialogVisible = computed(()=> props.isShow)

const closeDialog = () => {
    emit('close', false);
}

// 复制链接
const invite_url = ref("https://system.sialchina.cn/api/links?id=1684");
const copyUrl = (url) => {
    const input = document.createElement("input");
    input.value = url;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    input.remove();
    showMsg.success("复制成功，转发链接邀请客户");
};
</script>

<style lang="less">
.invitevst-pop {
    .fcbase {
        color: #ec664d !important;
    }
    .el-dialog__header {
        height: 54px;
        background-color: #ec664d;
        margin-right: 0;
    }
    .el-dialog__headerbtn {
        top: 0;
        .el-icon {
            height: 100%;
        }
        .el-dialog__close {
            color: #fff;
            &:hover {
                color: #ff984d;
            }
        }
        
    }
    .el-dialog__body {
        padding: 0;
    }
    .pop-con {
        background-color: #fff;
        background-image: url("@/assets/images/taozi.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 120%;
        padding: 50px 20px;
        min-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .tit {
            text-align: center;
            font-size: 26px;
            margin-bottom: 15px;
            .strong {
                font-size: 32px;
                color: #ec664d;
            }
        }
        .tit1 {
            font-weight: bold;
            font-size: 26px;
        }
        .tit2 {
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
        }
        .data {
            font-size: 22px;
            margin-bottom: 15px;
            text-align: center;
            strong {
                font-size: 26px;
            }
        }
    }
    .img {
        text-align: center;
        img {
            max-width: 205px;
            width: 100%;
        }
    }
    .btnbox {
        text-align: center;
        margin-top: 20px;
    }
    .btn {
        display: inline-block;
        padding: 8px 20px;
        background-color: #ec664e;
        color: #fff;
        box-shadow: 0 0 5px #ec664e;
        border: 0;
        border-radius: 30px;
        font-size: 26px;
        .transition;
        &:hover {
            opacity: 0.8;
        }
    }

}

@media screen and (max-height: 900px) {
    .invitevst-pop {
        .el-dialog__header {
            height: 50px;
            .el-dialog__headerbtn {
                width: 50px;
                height: 50px;
            }
        }
        .pop-con {
            padding: 30px 20px;
            .tit {
                text-align: center;
                font-size: 22px;
                margin-bottom: 15px;
                .strong {
                    font-size: 28px;
                    color: #ec664d;
                }
            }
            .tit1 {
                font-weight: bold;
                font-size: 22px;
            }
            .tit2 {
                font-size: 18px;
                margin-bottom: 15px;
                text-align: center;
            }
            .data {
                font-size: 18px;
                margin-bottom: 15px;
                text-align: center;
                strong {
                    font-size: 22px;
                }
            }
        }
        .img img {
            max-width: 160px;
        }
        .btn {
            font-size: 22px;
        }
    }
}
</style>