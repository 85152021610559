export function tongjiHeadEn() {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://www.googletagmanager.com/gtag/js?id=G-30GR4W43EY';
    scriptElement.setAttribute('async', 'true');
    document.head.appendChild(scriptElement);

    const initScriptElement = document.createElement('script');
    initScriptElement.textContent = `
    window.dataLayer = window.dataLayer || []; 
    function gtag(){dataLayer.push(arguments);} 
    gtag('js', new Date()); 

    gtag('config', 'G-30GR4W43EY'); 
    `;
    document.head.appendChild(initScriptElement);
}


export function tongjiHead() {

    const scriptElement = document.createElement('script');
    scriptElement.textContent = `
    window._agl = window._agl || [];
    (function () {
        _agl.push(
            ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
        );
        (function () {
            var agl = document.createElement('script');
            agl.type = 'text/javascript';
            agl.async = true;
            agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(agl, s);
        })();
    })();
    `;
    document.head.appendChild(scriptElement);

    const scriptElement2 = document.createElement('script');
    scriptElement2.textContent = `
    (function (w, d, t, s, q, m, n) {
        if (w.sguic) return;
        q = w.sguic = function () {
            q.process ? q.process.apply(null, arguments) : q.queue.push(arguments);
        };
        q.queue = [];
        m = d.getElementsByTagName(t)[0];
        n = d.createElement(t);
        n.src = s;
        n.async = true;
        m.parentNode.insertBefore(n, m);
    })(window, document, 'script', '//jstatic.sogoucdn.com/sdk/uic-pub.js');
    `;
    document.head.appendChild(scriptElement2);
}


export function tongjiFoot() {

    const scriptElement = document.createElement('script');
    scriptElement.textContent = `
    var _hmt = _hmt || [];
    window._hmt = _hmt;
    (function() {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?9e5f9378b90fb38a902e254ac170f88e";
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
      })();
    `;
    document.body.appendChild(scriptElement);

    var linkflowtech = document.createElement("script");
    linkflowtech.src = "//static.linkflowtech.com/linkflow.min.js?token=NjY1LWY3ZTZlZjBhLWE1ZTAtNGU1Zi04ZDFmLTg2MDNhNGJlZmU0NA%3D%3D&baseDomain=https://app.linkflowtech.com";
    document.body.appendChild(linkflowtech);
    
    const scriptElement2 = document.createElement('script');
    scriptElement2.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'UA-34767525-5');
    `;
    document.body.appendChild(scriptElement2);

    
    const scriptElement3 = document.createElement('script');
    scriptElement3.textContent = `
    (function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
    b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
    s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
    })(window,document,"script","_qha",439950,false);
    `;
    document.body.appendChild(scriptElement3);
}