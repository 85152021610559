import axios from "axios";
import request from "@/utils/request";


/**
 * 获取展会基本信息
 * @params {*}  
 * @returns
 */
 export function getExpoList(params) {
  return request({
    url: "/expo_list",
    method: "GET",
    params,
  });
}

/**
 * 获取展会区域
 * @params {*}  
 * @returns
 */
export function expoAreaList(params) {
  return request({
    url: "/expo_area_list",
    method: "GET",
    params,
  });
}

/**
 * 获取当届参展的展会区域
 * @params {*}  
 * @returns
 */
export function getAreaList(params) {
  return request({
    url: "/get_area_list",
    method: "GET",
    params,
  });
}

/**
 * 国家列表
 * @params {*}  
 * @returns
 */
export function getCountryList(params) {
  return request({
    url: "/ajax/country",
    method: "GET",
    params,
  });
}


/**
 * 省市
 * @params {*} pid
 * @returns
 */
 export function getRegionList(params) {
    return request({
      url: "/ajax/region",
      method: "GET",
      params,
    });
  }
  
  

/**
 * 展品分类列表
 * @params {*} 
 * @returns
 */
 export function getIndustryList(params) {
  return request({
    url: "/industry_list",
    method: "GET",
    params,
  });
}
  


/**
 * 公司性质列表
 * @params {*} 
 * @returns
 */
 export function getNatureTypeList(params) {
  return request({
    url: "/nature_type_list",
    method: "GET",
    params,
  });
}



/**
 * 目标客户列表
 * @params {*} expo_area_id 展区ID
 * @returns
 */
 export function getMarketList(params) {
  return request({
    url: "/market_list",
    method: "GET",
    params,
  });
}


/**
 * 主题馆--展位预定
 * @data {*} expo_area_id 展区ID
 * @returns
 */
 export function getPavilion(params) {
  return request({
    url: "/exhibitor/pavilion",
    method: "GET",
    params,
  });
}


/**
 * 目标市场、招代理区域列表
 * @data {*} 
 * @returns
 */
 export function getCountryTree(params) {
  return request({
    url: "/country_list",
    method: "GET",
    params,
  });
}



/**
 * 上传图片到七牛
 * @data {*} file
 * @returns
 */
 export function uploadImage(params) {
  return request({
    url: "/exhibitor/upload_image",
    method: "GET",
    params,
  });
}


/**
 * 获取兴趣主题
 * @returns
 */
export function getInterestThemeList(params) {
  return new Promise((resolve, reject) => {
    axios.get("https://visitor.sialchina.cn/visitorapi/getInterestThemeList",params).then((res)=>{
      resolve(res.data)
    }).catch((err)=>{
      reject(err.data)
    })
  }) 
  
}


/**
 * 判断会刊和展品是否已完成
 * @data {*} 
 * @returns
 */
export function getVerifyInfo(params) {
  return request({
    url: "/verify_information",
    method: "GET",
    params,
  });
}