import request from "@/utils/request";

/**
 * 注册-个人信息
 * @data {*}
 * @returns
 */
export function doReg(data) {
  return request({
    url: "/do_reg",
    method: "POST",
    data,
  });
}

/**
 * 展品分类列表
 * @params {*}  
 * @returns
 */
export function industryList(params) {
  return request({
    url: "/industry_list",
    method: "GET",
    params,
  });
}


/**
 * 获取权限节点
 * @data 
 * @returns
 */
 export function getRules(params) {
  return request({
    url: "/rule",
    method: "GET",
    params,
  });
}



/**
 * 会员-基本信息
 * @data {*}
 * @returns
 */
export function getProfile(params) {
  return request({
    url: "/company/info",
    method: "GET",
    params,
  });
}

/**
 * 基本信息更新
 * @data {*} 
 * @returns
 */
export function saveProfile(data) {
  return request({
    url: "/company/save",
    method: "POST",
    data,
  });
}

/**
 * 公司信息
 * @data {*}
 * @returns
 */
 export function getAccount(params) {
  return request({
    url: "/company/account",
    method: "GET",
    params,
  });
}

/**
 * 会员-更新密码
 *
 * @returns
 */
export function updatePwd(data) {
  return request({
    url: "/company/updatePwd",
    method: "POST",
    data,
  });
}


/**
 * 参展管理--获取logo
 * @params {*}
 * @returns
 */
 export function getLogo(params) {
  return request({
    url: "/logo",
    method: "GET",
    params,
  });
}

/**
 * 参展管理--保存logo
 *  logo
 * @returns
 */
export function saveLogo(data) {
  return request({
    url: "/logo_save",
    method: "POST",
    data,
  });
}


/**
 * 确认函
 * @params {expo_area_id} 展会id
 * @returns
 */
 export function getConfirmation(params) {
  return request({
    url: "/getConfirmation",
    method: "GET",
    params,
  });
}



/**
 * 找回密码
 *
 * @returns
 */
export function findPwd(data) {
  return request({
    url: "/findPwd",
    method: "POST",
    data,
  });
}

/**
 * 修改密码
 *
 * @returns
 */
export function changePassword(data) {
  return request({
    url: "/changePassword",
    method: "POST",
    data,
  });
}


/**
 * 会员----联系人
 * @returns
 */
 export function getContacts(params) {
  return request({
    url: "/company/contacts",
    method: "GET",
    params,
  });
}

/**
 * 法国单点登录
 *
 * @returns
 */
export function frenchSsoLogin(data) {
  return request({
    url: "/french_ssoLogin",
    method: "POST",
    data,
  });
}

