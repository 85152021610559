import { ElMessage } from 'element-plus';

const showMessage = (type, message, option, callback) => {

  var opt = {
    type: type,
    message: message,
    duration: 2000,
    dangerouslyUseHTMLString: true,
    offset: window.screen.height/2 - 100,
    onClose: callback,
  }
  Object.assign(opt, option)
  ElMessage(opt);
};

export default {
  success(message, option, callback) {
    if(!message) {
      return;
    }
    showMessage('success', message, option, callback);
  },
  warning(message, option, callback) {
    if(!message) {
      return;
    }
    showMessage('warning', message, option, callback);
  },
  error(message, option, callback) {
    if(!message) {
      return;
    }
    showMessage('error', message, option, callback);
  },
  info(message, option, callback) {
    if(!message) {
      return;
    }
    showMessage('info', message, option, callback);
  },
};
