<template>
  <el-form
    ref="formRef"
    :model="formModel"
    :rules="formRules"
    :hide-required-asterisk="false"
    label-position="top"
  >
    <div class="form-box">
      <div class="form-title">{{$t("info.company info")}}</div>
      <el-row :gutter="30">
        <el-col :sm="12">
          <el-form-item v-if="$t('lang') == 'cn'" prop="company_cn" :label="$t('info.company')">
            <el-input
              type="text"
              v-model="formModel.company_cn"
              placeholder="请输入公司名称"  
            ></el-input>
          </el-form-item>
          <el-form-item  v-else prop="company_en" :label="$t('info.company')">
            <el-input
              type="text"
              v-model="formModel.company_en"
              placeholder="Please input company name"  
             
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item prop="country_id" :label="$t('info.country')">
            <el-select
              v-model="formModel.country_id"
              :placeholder="$t('please select')"
              filterable
              :filter-method="$t('lang') == 'en' ? handleCountryFilter : ''"
            >
              <el-option disabled :label="$t('info.country')" :value="0" />
              <template v-for="item in countryList" :key="item.id">
                <el-option
                  :label="$t('lang') == 'cn' ? item.name_cn : item.name_en"
                  :value="item.id"
                />
              </template>
              
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :sm="12" v-show="$t('lang') == 'cn' && formModel.country_id == 256">
            <el-form-item label="省市" required>
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item prop="province_id">
                    <el-select
                    v-model="formModel.province_id"
                    class="form-input"
                    :placeholder="$t('please select')"
                    @change="changeRegion(formModel.province_id, 'cityList', 'zoneList')"
                    >
                    <el-option disabled label="请选择" :value="0" />
                    <template v-for="option in provinceList" :key="option.id">
                        <el-option :label="option.name_cn" :value="option.id" />
                    </template>
                    </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item prop="city_id">
                    <el-select
                    v-model="formModel.city_id"
                    class="form-input"
                    :placeholder="$t('please select')"
                    >
                    <!-- @change="changeRegion(formModel.city_id, 'zoneList')" -->
                    <el-option disabled label="请选择" :value="0" />
                    <template v-for="option in cityList" :key="option.id">
                        <el-option :label="option.name_cn" :value="option.id" />
                    </template>
                    </el-select>
                </el-form-item>
              </el-col>

              <!-- <el-col :span="8">
                <el-form-item prop="zone_id">
                    <el-select
                    v-model="formModel.zone_id"
                    class="form-input"
                    :placeholder="$t('please select')"
                    >
                    <el-option disabled label="请选择" :value="0" />
                    <template v-for="option in zoneList" :key="option.id">
                        <el-option :label="option.name_cn" :value="option.id" />
                    </template>
                    </el-select>
                </el-form-item>
              </el-col> -->
            </el-row>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item prop="address_cn" label="公司地址" v-if="$t('lang') == 'cn'">
            <el-input
              type="text"
              v-model="formModel.address_cn"
              placeholder="请输入公司地址"
            ></el-input>
          </el-form-item>
          <el-form-item prop="address_en" label="Address" v-else>
            <el-input
              type="text"
              v-model="formModel.address_en"
              placeholder="Please input address"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <div class="form-box">
      <div class="form-title">{{$t("info.contacts info")}}</div>
      <el-row :gutter="30">
        <el-col :sm="12">
          <el-form-item prop="truename" :label="$t('info.truename')">
            <el-input
              type="text"
              v-model="formModel.truename"
              :placeholder="`${$t('please input')}${$t('your')}${$t('info.truename')}`"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item prop="mobile" :label="$t('info.mobile')">
            <el-input
              type="text"
              v-model="formModel.mobile"
              :placeholder="`${$t('please input')}${$t('your')}${$t('info.mobile')}`"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item prop="email" :label="$t('info.email')">
            <el-input
              type="text"
              v-model="formModel.email"
              :placeholder="`${$t('please input')}${$t('your')}${$t('info.email')}`"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>

    <div class="submitbtn-box">
      <el-button
          type="primary"
          @click="submitForm"
          :loading="loading"
          size="large"
          >{{$t("info.submit")}}</el-button
      >
      </div>
  </el-form>
</template>

<script setup>
import showMsg from "@/utils/message";
import { reactive, ref, unref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

import { isMobile, isEmail } from "@/utils/common";
import { doReg } from "@/api/auth";
import { getCountryList, getRegionList } from "@/api/common";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";


    const { t, locale } = useI18n();
    const router = useRouter();
    const store = useStore();

    const loading = ref(false);

    const formRef = ref(null);
    const formModel = reactive({
      app_lang: locale.value,
    });
    const formRules = reactive({
      mobile: [
        { required: true, trigger: "blur", message: `${t('please input')}${t('your')}${t('info.mobile')}` },
        {
          validator: (rule, val) => {
          if(!isMobile(val)) {
              return Promise.reject(`${t('please input')}${t('a valid')}${t('info.mobile')}`);
          }
          return Promise.resolve();
          }, 
          trigger: ["blur", "change"]
        }
      ],
      email: [
        { required: true, trigger: "blur", message: `${t('please input')}${t('your')}${t('info.email')}` },
        {
          validator: (rule, val) => {
          if(!isEmail(val)) {
              return Promise.reject(`${t('please input')}${t('a valid')}${t('info.email')}`);
          }
          return Promise.resolve();
          }, 
          trigger: ["blur", "change"]
        }
      ],
      company_cn: [
        { required: true, trigger: "blur", message: `${t('please input')}${t('info.company')}` },
      ],
      company_en: [
        { required: true, trigger: "blur", message: `${t('please input')}${t('info.company')}` },
      ],
      truename: [
        { required: true, trigger: "blur", message: `${t('please input')}${t('your')}${t('info.truename')}` },
      ],
      country_id: [
        { required: true, trigger: "change", message: `${t('please select')}${t('info.country')}` },
      ],
      province_id: [
        { validator: (rule, val) => {
            if(formModel.country_id == 256 && !val && locale.value != 'en') {
                return Promise.reject('请选择省份');
            }
            return Promise.resolve();
        },
        trigger: "change"
      },
      ],
      city_id: [
        { validator: (rule, val) => {
          if(formModel.country_id == 256 && !val && locale.value != 'en') {
              return Promise.reject('请选择城市');
          }
            return Promise.resolve();
        }, trigger: "change"
      },
      ],
      address_cn: [
        { required: true, trigger: "blur", message: "请输入公司地址" },
      ],
      address_en: [
        { required: true, trigger: "blur", message: "Please enter company address" },
      ],
    })

    
    const countryList = ref([])
    const allCountryList = ref([]); //记录所有
    const provinceList = ref([])
    const cityList = ref([])
    const zoneList = ref([])

    // 获取国家列表
    getCountryList().then((res) => {
      const { data } = res;
      countryList.value = data;
      allCountryList.value = data;
    }).catch(()=>{});

    // 获取省份列表
    getRegionList(0).then((res) => {
      const { data } = res;
      provinceList.value = data;
      cityList.value = [];
      zoneList.value = [];
    }).catch(()=>{});

    function changeRegion(pid,obj1, obj2) {
      if(obj2) zoneList.value = [];
      if(obj1 == 'cityList') {
        cityList.value = [];
      }
      if(obj1 == 'zoneList') {
        zoneList.value = [];
      }
      formModel.city_id = 0;
      getRegionList({pid}).then((res)=>{
        const {data} = res;
        console.log("data",data);
        if(obj1 == 'cityList') {
          cityList.value = data;
        }
        if(obj1 == 'zoneList') {
          zoneList.value = data;
        }
        console.log("cityList",cityList.value);
      }).catch(()=>{})
    }

    const handleCountryFilter = (query) => {
      const list = allCountryList.value;
      if (query) {
        countryList.value = list.filter((item) => {
          return item.name_en.toLowerCase().indexOf(query.toLowerCase()) == 0;
        });
      } else {
        countryList.value = allCountryList.value;
      }
    };

    const emit = defineEmits(['regSuccess'])
    const submitForm = () => {
      unref(formRef).validate((valid) => {
        if (valid) {
          loading.value = true;

          doReg(formModel).then((res)=>{
            const { data } = res;
            emit("regSuccess", data);
            Cookies.set("account",data.account);
            Cookies.set("pwd", CryptoJS.AES.encrypt(data.password, process.env.VUE_APP_PWD_KEY));
            loading.value = false;
          }).catch((err)=>{
            showMsg.error(err.msg);
            loading.value = false;
          })
        }
      });
    };
</script>

<style lang="less" scoped>
</style>
