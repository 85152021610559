<template>
  <div class="login-wrap" v-loading="loading">
    <div class="login-box">
      <div class="language-box-wrap">
        <div class="language-box">
          <div class="language-top J-language">
            <a
              :href="`${$store.state.domain}/login`"
              :class="{ curr: $t('lang') == 'cn' }"
              >中文
            </a>
            <span></span>
            <a
              :href="`${$store.state.domain_en}/login`"
              :class="{ curr: $t('lang') == 'en' }"
              >EN
            </a>
          </div>
        </div>
      </div>

      <div class="login-container">
        <div class="tit">{{ $t("exhibitor title") }}</div>
        <div class="rig-wrap login-con">
          <el-form
            ref="formRef"
            :model="formModel"
            :rules="formRules"
            :hide-required-asterisk="true"
            class="login-formbox"
          >
            <el-form-item prop="account">
              <el-input
                type="text"
                v-model.trim="formModel.account"
                suffix-icon="User"
                :placeholder="$t('input account')"
                class="form-input"
              />
            </el-form-item>

            <el-form-item prop="pwd">
              <el-input
                type="password"
                v-model.trim="formModel.pwd"
                suffix-icon="Key"
                :placeholder="$t('input password')"
                class="form-input"
                @keyup.enter="DoLogin"
              />
            </el-form-item>
          </el-form>

          <div class="remember">
            <el-checkbox
              v-model="is_remember"
              :label="$t('remember password')"
            />
          </div>

          <div class="submit-box">
            <el-button type="primary" class="btn-primary2" @click="DoLogin">{{
              $t("login")
            }}</el-button>
            <router-link :to="`${$t('baseurl')}/reg`"
              ><el-button type="warning">{{
                $t("reg")
              }}</el-button></router-link
            >
          </div>

          <div class="tip">
            <p class="p1">
              <el-icon><WarningFilled /></el-icon> {{ $t("tip") }}
            </p>
            <p class="p2">
              1、{{
                $t(
                  "The login user name and password shall be provided by the organizer."
                )
              }}
              <br />
              2、{{
                $t("If you have any questions, please contact your sales.")
              }}
            </p>
          </div>
        </div>
      </div>

      <div class="account-box">
        <img data-offset="20" class="parallax" src="@/assets/images/img2.png" />

        <div class="box">
          <div class="text">
            {{ $t("shanghai introduction") }} <br />
            {{ $t("shenzhen introduction") }}
          </div>
        </div>
      </div>
    </div>

    <div class="login-left">
      <div class="logo">
        <img src="@/assets/images/logo.png" width="170" />
      </div>
      <div class="top">
        <div class="img">
          <p class="tit" v-if="$t('lang') == 'en'">{{ $t("SIAL in China") }}</p>

          <div class="exhinfo">
            <div class="item" v-for="item in expo_area_list" :key="item.id">
              <p class="tit">
                {{ $t("lang") == "en" ? item.expo_name_en : item.expo_name }}
              </p>
              <p class="p1">
                {{ setFormatTime(item.start_time, item.end_time, "Y.M.D") }}
              </p>
              <p class="p2">
                {{ $t("lang") == "en" ? item.expo_addr_en : item.expo_addr }}
              </p>
            </div>
          </div>

          <a :href="$store.state.home_domain" target="_blank" class="linka"
            >{{ $t("official website") }} <el-icon><Right /></el-icon
          ></a>
        </div>
      </div>
      <div class="inspire">
        <el-carousel indicator-position="none" arrow="never" :interval="5000">
          <el-carousel-item v-for="item in 3" :key="item">
            <img src="@/assets/images/img1.png" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <p class="copyright">
        {{ $t("footer.copyright") }}
        <a href="http://beian.miit.gov.cn/" target="_blank"
          >京ICP备05075462号-1</a
        >
      </p>
    </div>
  </div>
</template>

<script setup>
import showMsg from "@/utils/message";
import { reactive, ref, unref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { useI18n } from "vue-i18n";

import { getExpoList } from "@/api/common";
import { rangeFormatTime } from "@/utils/common";

const { t, locale } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();

const loading = ref(false);

// 获取展会基本信息
const expo_area_list = ref([]);
getExpoList()
  .then((res) => {
    const { data } = res;
    expo_area_list.value = data;
  })
  .catch(() => {});

// 日期格式
const setFormatTime = (start_time, end_time, format) => {
  return rangeFormatTime(start_time, end_time, format);
};

const is_remember = ref(false);
const formRef = ref(null);
const formModel = reactive({
  account: Cookies.get("account"),
  pwd: Cookies.get("pwd")
    ? CryptoJS.AES.decrypt(
        Cookies.get("pwd"),
        process.env.VUE_APP_PWD_KEY
      ).toString(CryptoJS.enc.Utf8)
    : "",
  app_lang: locale.value,
});
const formRules = reactive({
  account: [{ required: true, trigger: "blur", message: t("input account") }],
  pwd: [{ required: true, trigger: "blur", message: t("input password") }],
});

// 重定向处理
const redirect = ref("");
watchEffect(() => {
  redirect.value = route.query && route.query.redirect;
});

// 登录
const DoLogin = () => {
  unref(formRef).validate((valid) => {
    if (valid) {
      loading.value = true;

      if (is_remember.value) {
        Cookies.set("account", formModel.account);
        Cookies.set(
          "pwd",
          CryptoJS.AES.encrypt(formModel.pwd, process.env.VUE_APP_PWD_KEY)
        );
      } else {
        Cookies.remove("account");
        Cookies.remove("pwd");
      }

      store
        .dispatch("user/login", formModel)
        .then(() => {
          // 登录成功，用户跳转
          loading.value = false;
          var url = "/";
          if (window.location.pathname.indexOf("/en") > -1) {
            url = "/en";
          }
          router.push({ path: unref(redirect) || url });
        })
        .catch((err) => {
          loading.value = false;
          showMsg.error(err.msg);
        });
    }
  });
};
</script>

<style lang="less"></style>
