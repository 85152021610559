import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import i18n from "./locales/i18n";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import "@/assets/font-awesome-4.7.0/css/font-awesome.min.css";

import "@/assets/css/element.less";
import "@/assets/css/base.less";
import "@/assets/css/response.less";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(ElementPlus);
app.use(router);
app.use(store);
app.use(i18n);
app.mount("#app");
